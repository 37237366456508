import React, { PropsWithChildren, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '@/components/layout/header';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@mui/material/CircularProgress';

export const LoadingSpinner = () => (
  <div className="my-20 w-full text-center text-lg">
    <div className="mx-auto my-10">
      <CircularProgress style={{ color: '#0DB189' }} />
    </div>
    <div className="my-10">Loading...</div>
  </div>
);

export const Layout = ({ children }: PropsWithChildren) => {
  const { isLoading } = useAuth0();
  if (isLoading) throw new Promise((resolve) => setTimeout(resolve, 100));
  return (
    <div className="relative flex h-[100dvh] flex-col overflow-hidden bg-[#EFF2F0]">
      <Suspense>
        <Header />
      </Suspense>
      <div className="flex flex-1 flex-row overflow-auto">
        <React.Suspense fallback={<LoadingSpinner />}>{children ? children : <Outlet />}</React.Suspense>
      </div>
    </div>
  );
};
