import { useMutation, useQuery } from 'urql';
import { graphql } from '@/gql';
import { ResendUserRegistrationMailInput, VerifyUserRegistrationMailInput } from '@/gql/graphql';
import { useAuth0 } from '@auth0/auth0-react';

const UserVerified_Query = graphql(/* GraphQL */ `
  query UserVerified_Query($isUserAccess: Boolean!) {
    userViewer @include(if: $isUserAccess) {
      emailVerified
    }
  }
`);

export const useVerifyQuery = (): {
  emailVerified: boolean | undefined;
  refetch: () => void;
  isAuthenticated: boolean;
} => {
  const { isAuthenticated } = useAuth0();
  const [{ data }, refetch] = useQuery({
    query: UserVerified_Query,
    variables: {
      isUserAccess: isAuthenticated,
    },
  });

  return {
    emailVerified: data?.userViewer?.emailVerified,
    refetch,
    isAuthenticated,
  };
};

const OnVerifyUserRegistrationMail_Mutation = graphql(/* GraphQL */ `
  mutation verifyUserRegistrationMail($input: VerifyUserRegistrationMailInput!) {
    verifyUserRegistrationMail(input: $input) {
      __typename
      ok
      clientMutationId
    }
  }
`);

const OnResendRegistrationMail_Mutation = graphql(/* GraphQL */ `
  mutation resendRegistrationMail($input: ResendUserRegistrationMailInput!) {
    resendUserRegistrationMail(input: $input) {
      __typename
      clientMutationId
    }
  }
`);

export const useVerify = () => {
  const [, onVerifyMutation] = useMutation(OnVerifyUserRegistrationMail_Mutation);
  const onVerify = async (input: VerifyUserRegistrationMailInput) => {
    if (!input.token) {
      throw new Error('token is required');
    }
    const res = await onVerifyMutation({
      input,
    });
    if (res.error) {
      throw res.error;
    }
    return res.data?.verifyUserRegistrationMail;
  };

  const [, onResendMutation] = useMutation(OnResendRegistrationMail_Mutation);

  const onResend = async (input: ResendUserRegistrationMailInput) => {
    const res = await onResendMutation({
      input,
    });
    if (res.error) {
      throw res.error;
    }
    return res.data?.resendUserRegistrationMail;
  };
  return { onVerify, onResend };
};
