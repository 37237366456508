import { PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useVerifyQuery } from '@/features/auth/verify/repository/useVerify';

export const VerifyRedirect = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { emailVerified, isAuthenticated } = useVerifyQuery();
  const mustRedirect = isAuthenticated && !emailVerified && location.pathname !== '/auth/verify';

  useEffect(() => {
    if (mustRedirect) {
      navigate('/auth/verify');
    }
  }, [mustRedirect, navigate]);

  return <>{children}</>;
};
