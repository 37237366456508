/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { cacheExchange, createClient, Exchange, fetchExchange, Operation, Provider } from 'urql';
import { fromPromise, fromValue, map, mergeMap, pipe } from 'wonka';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

type Props = { children: React.ReactElement };

const audience = import.meta.env.VITE_AUTH0_AUDIENCE as string;

export const Auth0UrqlProvider = ({ children }: Props) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const fetchOptionsExchange =
    (fn: any): Exchange =>
    ({ forward }) =>
    (ops$) => {
      return pipe(
        ops$,
        mergeMap((operation: Operation) => {
          const result = fn(operation.context.fetchOptions);
          return pipe(
            (typeof result.then === 'function' ? fromPromise(result) : fromValue(result)) as any,
            map((fetchOptions: RequestInit | (() => RequestInit)) => ({
              ...operation,
              context: { ...operation.context, fetchOptions },
            })),
          );
        }),
        forward,
      );
    };

  const client = createClient({
    url: import.meta.env.VITE_GRAPHQL_ENDOPOINT || 'https://stg.api.giho.jp/graphql',
    requestPolicy: 'cache-and-network',
    suspense: true,
    exchanges: [
      cacheExchange,
      fetchOptionsExchange(async (fetchOptions: any) => {
        let accessToken = '';
        try {
          if (isAuthenticated) {
            const awaited = await getAccessTokenSilently({
              authorizationParams: {
                audience,
              },
            });
            accessToken = awaited;
          }
        } catch (e) {
          Sentry.captureException(e);
        }

        return Promise.resolve({
          ...fetchOptions,
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : '',
          },
        });
      }),
      fetchExchange,
    ],
  });

  return <Provider value={client}>{children}</Provider>;
};
