import React, { PropsWithChildren, Suspense } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LoadingSpinner, Layout as UILayout } from '@/components/ui-parts/layout';
import { VerifyRedirect } from '@/features/auth/verify/components/VerifyRedirect';

export const Layout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname !== '/maintenance' && import.meta.env.VITE_MAINTENANCE === 'true') {
      navigate('/maintenance');
      return;
    }
  }, [location.pathname, navigate]);
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <VerifyRedirect>
        <UILayout>{children ? children : <Outlet />}</UILayout>
      </VerifyRedirect>
    </Suspense>
  );
};
